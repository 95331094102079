<template>
  <div class="main-con">
    <div class="list-item">
      <div class="item-row">
        <span>主体信息</span>
        <span>以下信息由主体提供</span>
      </div>
      <div class="info">
        <h1>主体名称</h1>
        <h1>{{ subjectData.subjectName }}</h1>
      </div>
      <div class="info">
        <h1>主体类别</h1>
        <h1>{{ subjectData.subjectType }}</h1>
      </div>
      <div class="info">
        <h1>所属行业</h1>
        <h1>{{ subjectData.tradeType }}</h1>
      </div>
      <div class="info">
        <h1>联系人员</h1>
        <h1>{{ subjectData.contacts }}</h1>
      </div>
      <div class="info">
        <h1>联系方式</h1>
        <h1 class="phone">
          <a :href="`tel:${subjectData.createCertPhone}`"></a>
          {{ subjectData.createCertPhone }}
        </h1>
      </div>
      <div class="info">
        <h1>联系地址</h1>
        <h1>{{ subjectData.fullRegionName }}</h1>
      </div>
    </div>
    <div class="list-item">
      <div class="item-row">
        <span>资质认证</span>
      </div>
      <div class="auth-info-img">
        <img
          @click="isGreenHand()"
          v-if="subjectData.isGreen == 1"
          style="width: 40px; height: 40px"
          :src="require(`@/assets/images/chanp.png`)"
          title="绿色食品标志"
          alt=""
        />
        <img
          @click="isIndicationHand()"
          v-if="subjectData.isIndication == 1"
          style="width: 40px; height: 40px; padding: 0 20px"
          :src="require(`@/assets/images/biaoz.jpg`)"
          title="农产品地理标志"
          alt=""
        />
        <img
          @click="isOrganicHand()"
          v-if="subjectData.isOrganic == 1"
          style="width: 40px; height: 40px"
          :src="require(`@/assets/images/youj.png`)"
          title="有机食品标志"
          alt=""
        />
        <span
          v-if="
            subjectData.isGreen != 1 &&
            subjectData.isIndication != 1 &&
            subjectData.isOrganic != 1
          "
          class="auth"
          >无</span
        >
      </div>
    </div>
    <div class="list-item">
      <div class="item-row">
        <span>产地地图</span>
        <span>以下信息由主体提供</span>
      </div>
      <div class="map-c">
        <div id="container" class="mapin" />
      </div>
      <!-- <div class="showMap">
        <p>到这去<van-icon class="icon" size="18" name="guide-o" /></p>
      </div> -->
    </div>
    <div v-if="showPic.length != 0" class="list-item">
      <div class="item-row">
        <span>产地信息</span>
        <span>以下信息由主体提供</span>
      </div>
      <div class="auth-info">
        <img
          v-for="(item, idx) in showPic"
          :key="idx"
          :src="item"
          class="auth-img"
        />
      </div>
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
export default {
  props: {},
  watch: {},
  data() {
    return {
      subjectData: {},
      subjectList: [],
      tradeList: [],
      showPic: [],
    };
  },
  async mounted() {
    let item = this.$route.query;
    this.subjectId = item.subId;
    await this.dictionary();
    try {
      let params = {
        subjectId: item.subId,
      };
      // this.$global.loading.show();
      let response = await cateApi.subject(params);
      // this.$global.loading.hide();
      let data = response.data;
      for (let index = 0; index < this.subjectList.length; index++) {
        const element = this.subjectList[index];
        if (element.value == data.subjectType) {
          data.subjectType = element.label;
        }
      }
      if (data.tradeType) {
        let tradeType = data.tradeType.split(",");
        let tradeTypeList = [];
        for (let index = 0; index < this.tradeList.length; index++) {
          const element = this.tradeList[index];
          for (let tindex = 0; tindex < tradeType.length; tindex++) {
            const telement = tradeType[tindex];
            if (element.value == telement) {
              tradeTypeList.push(element.label);
            }
            data.tradeType = tradeTypeList.join(",");
          }
        }
      }
      let gpsLongitude = Number(data.gpsLongitude);
      let gpsLatitude = Number(data.gpsLatitude);
      this.initMap();
      setTimeout(() => {
        var map = new AMap.Map("container", {
          zoom: 10, //设置地图显示的缩放级别
          center: [gpsLongitude, gpsLatitude], //设置地图中心点坐标
          layers: [new AMap.TileLayer.Satellite()], //设置图层,可设置成包含一个或多个图层的数组
          mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
          viewMode: "2D", //设置地图模式
        });
        // 点标记显示内容，HTML要素字符串
        let markerContent = `<div class="custom-content-marker">' +
          '   <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">' +
          '  <p class="maptp">${data.subjectName}</p> ' +
          "</div>`;
        // map.add(map);
        // 创建标注点
        let marker = new AMap.Marker({
          position: [gpsLongitude, gpsLatitude], // 标注点位置坐标
          content: markerContent,
          // 以 icon 的 [center bottom] 为原点
          // offset: new AMap.Pixel(-13, -30),
        });
        // 将标注点添加到地图上
        map.add(marker);
      }, 800);
      this.subjectData = data;
    } catch (error) {
      console.log(error);
      // this.$global.loading.hide();
    }
  },
  methods: {
    async dictionary() {
      await Promise.all([
        this.getsubject(),
        this.getshowPlan(),
        this.getTrade(),
      ]);
    },
    initMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "cedd8498e9c81d01c23edd92e7b4f7a7",
      };
    },
    isGreenHand() {
      this.$toast("绿色食品标志");
    },
    isIndicationHand() {
      this.$toast("农产品地理标志");
    },
    isOrganicHand() {
      this.$toast("有机食品标志");
    },
    //主体类别
    async getsubject() {
      try {
        let params = {
          dictType: "subject_type",
        };
        // this.$global.loading.show();
        let response = await cateApi.dict(params);
        // this.$global.loading.hide();
        let list = response.data;
        this.subjectList = list;
      } catch (error) {
        // this.$global.loading.hide();
      }
    },
    //所属行业
    async getTrade() {
      try {
        let params = {
          dictType: "trade_type",
        };
        this.$global.loading.show();
        let response = await cateApi.dict(params);
        this.$global.loading.hide();
        let list = response.data;
        this.tradeList = list;
      } catch (error) {
        this.$global.loading.hide();
      }
    },
    //产地信息
    async getshowPlan() {
      try {
        let params = {
          subjectId: this.subjectId,
        };
        // this.$global.loading.show();
        let response = await cateApi.showPlan(params);
        // this.$global.loading.hide();
        let { showPic } = response.data;
        this.showPic = showPic.split(",");
      } catch (error) {
        // this.$global.loading.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}
::v-deep .custom-content-marker img {
  width: 100%;
  height: 100%;
}
::v-deep .maptp {
  position: absolute;
  left: 30px;
  top: 0px;
  white-space: nowrap;
  padding: 5px 8px;
  border: 1px solid red;
}
.main-con {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  .list-item {
    background-color: #fff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    .item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 0.2rem;
      span {
        &:nth-child(1) {
          font-weight: 600;
        }
        &:nth-child(2) {
          color: #33bd7d;
          font-size: 0.24rem;
          font-weight: 600;
        }
      }
    }
    .info {
      line-height: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      h1 {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          color: #333;
          flex: 1;
          font-weight: 600;
        }
      }
      .phone {
        font-weight: 400 !important;
        color: #33bd7d !important;
      }
    }
    .auth-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.4rem;
      .auth-img {
        width: 100%;
        margin-bottom: 0.2rem;
      }
    }
    .showMap {
      background: #33bd7d;
      border: 0;
      border-radius: 0.2rem;
      box-shadow: 0 0 0.04rem #f1f1f1;
      height: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0.48rem;
      margin: 0.24rem 0;
      padding: 0;
      width: 100%;
      p {
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon {
          margin-left: 0.2rem;
        }
      }
    }
    .map-c {
      position: relative;
      width: 100%;
      height: 4rem;
      .mapin {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
  }
  .auth-info-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.4rem;
  }
}
</style>
